import 'nprogress/nprogress.css';
import 'simplebar-react/dist/simplebar.min.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';

if (
  process.env.REACT_APP_ENVIRONMENT === 'staging' ||
  process.env.REACT_APP_ENVIRONMENT === 'production'
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: [
      /NotAllowedError/,
      'ResizeObserver loop limit exceeded',
      'Upload canceled'
    ],
    environment: process.env.REACT_APP_ENVIRONMENT
  });
}

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <Sentry.ErrorBoundary fallback="An error has occurred">
              <App />
            </Sentry.ErrorBoundary>
          </BrowserRouter>
        </LocalizationProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);
