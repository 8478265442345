/* eslint-disable no-useless-escape */
export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const MAX_FILE_SIZE = 1024 * 1024 * 1024;
export const SUPPORTED_FORMATS = ['mp4', 'mov', 'quicktime'];

export const handleRegex = /^@(?:\w|\.|-)+/;
export const emailRegex =
  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
export const phoneRegExp = /^\+?[\d()-\s]{7,}$/;
export const igUsernamesRegex = /^\s*@[\w.]+((\s*,|\s*,\s*)@[\w.]+)*\s*$/;
export const visibleNamesRegex =
  /^\s*([\w.]+\s+)+([\w.]+\s+)*[\w.]+(\s*,\s*([\w.]+\s+)+([\w.]+\s+)*[\w.]+)*\s*$/;
export const instagramTikTokRegex =
  /^(https:\/\/(www\.tiktok\.com\/.+|vm\.tiktok\.com\/.+|www\.instagram\.com\/.+|www\.youtube\.com\/watch\?v=.+|youtu\.be\/.+))$/;
export const competitorsRegex =
  /^\s*(?:@\w+(?:[\s\/]\w+)*(?:\.\w+)*(?:\/\w+)?|\w+(?:[\s.]\w+)*)(?:\s*,\s*(?:@\w+(?:[\s\/]\w+)*(?:\.\w+)*(?:\/\w+)?|\w+(?:[\s.]\w+)*))*\s*$/;

// Regular expressions for TikTok, Instagram, Facebook, and Snapchat URLs
export const tiktokRegex = /https?:\/\/(www\.)?tiktok\.com\//i;
export const instagramRegex = /https?:\/\/(www\.)?instagram\.com\//i;
export const facebookRegex = /https?:\/\/(www\.)?facebook\.com\//i;
export const snapchatRegex = /https?:\/\/(www\.)?snapchat\.com\//i;
export const youtubeRegex = /https?:\/\/(www\.)?youtube\.com\//i;

export const recaptchaApiUrl = 'https://www.google.com/recaptcha/api.js';
export const recaptchaScriptId = 'recaptcha-key';
export const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const FILE_TYPE = {
  contract: 'PDF_CONTRACT',
  video: 'VIDEO'
};

export const OPTIONS = {
  OPTION_UNDER_18: 'OPTION_UNDER_18',
  OPTION_ABOVE_18: 'OPTION_ABOVE_18'
};

export const OPTIONS_UNDER_18_CONFIRMATION = [
  'I have not previously sent this video to any other page, submission form, or licensing company.',
  'I physically recorded this video.',
  'I am at least 13 years old.'
];

export const OPTIONS_ABOVE_18_CONFIRMATION = [
  'I have not previously sent this video to any other page, submission form, or licensing company.',
  'I physically recorded this video.'
];

export const PLATOFRMS = {
  TIKTOK: 'TIKTOK',
  INSTAGRAM: 'INSTAGRAM',
  FACEBOOK: 'FACEBOOK',
  SNAPCHAT: 'SNAPCHAT',
  YOUTUBE: 'YOUTUBE',
  OTHER: 'OTHER'
};

export const PLATFORM_ICON = {
  TIKTOK: '/static/icons/tiktok.svg',
  INSTAGRAM: '/static/icons/instagram.svg',
  FACEBOOK: '/static/icons/facebook.svg',
  SNAPCHAT: '/static/icons/snapchat.svg',
  YOUTUBE: '/static/icons/youtube.svg',
  OTHER: '/static/icons/default.svg'
};

export const caaPartnerType = {
  AUTHOR: 0,
  ASSIGNEE: 1
};

export const csTeamTypes = {
  WEB_FORM: 'WEB_FORM',
  IG_FORM: 'IG_FORM',
  FB_FORM: 'FB_FORM',
  CONTEST_FORM: 'CONTEST_FORM',
  SUBMISSION_FORMS: 'SUBMISSION_FORMS',
  CLEARANCE_DEV: 'CLEARANCE_DEV'
};

export const competitors = [
  { label: '@Bitch', value: 'BITCH' },
  { label: 'Caters Media', value: 'CATERSMEDIA' },
  { label: '@BarstoolSports', value: 'BARSTOOLSPORTS' },
  { label: '@CLIPS', value: 'CLIPS' },
  { label: 'Collab', value: 'COLLAB' },
  { label: '@Discover.Animal', value: 'DISCOVER.ANIMAL' },
  { label: 'Jukin Media', value: 'JUKINMEDIA' },
  { label: '@LOLvibez', value: 'LOLVIBEZ' },
  {
    label: '@Unilad/ContentBible/LADbible',
    value: 'UNILAD/CONTENTBIBLE/LADBIBLE'
  },
  { label: 'Viral Bear', value: 'VIRALBEAR' },
  { label: 'ViralHog', value: 'VIRALHOG' },
  { label: 'Viral Snare', value: 'VIRALSNARE' },
  { label: 'WooGlobe', value: 'WOOGLBOE' },
  { label: 'Benchmob', value: 'BENCHMOB' }
];

export const formFieldsNames = {
  file: 'file',
  videoUrl: 'video url',
  videoDesc: 'video description',
  whoRecorded: 'who recorded',
  videoRecordedAt: 'video recorded at',
  city: 'city',
  province: 'province',
  country: 'country',
  igUsername: 'instagram usernames',
  visibleName: 'names of people visible',
  firstName: 'first name',
  lastName: 'last name',
  phoneNumber: 'phone number',
  contactEmail: 'contact email',
  guardianName: 'guardian name',
  guardianEmail: 'guardian email',
  guardianPhoneNumber: 'guardian phone number',
  email: 'email',
  signature: 'signature',
  guardianSignature: 'guardian signature',
  tiktokHandle: 'tiktok handle',
  igHandle: 'instagram handle',
  checkboxes: 'all the checkboxes of adult',
  guardianCheckboxes: 'all the checkboxes of minor',
  adultPhoneValidation: 'Phone check box required',
  minorPhoneValidation: 'Phone check box required'
};

export const MESSAGES = {
  videoSubmitted: {
    title: 'Video already submitted',
    description: `Thanks for submitting your video. We already received your information. If you have any questions, please contact our support
    team at contact@bviral.com`
  },
  invalidLink: {
    title: 'Expired Link',
    description: `This link is either invalid or expired. Please contact our support team at contact@bviral.com if you think this is a mistake.`
  }
};
